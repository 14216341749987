import { NgModule } from '@angular/core';

import { RentalOfferDomainModule } from '@domains/rental-offer';
import { HandoverProtocolDomainModule } from '@domains/handover-protocol';
import { ViewingPassDomainModule } from 'src/app/domains/viewing-pass';
import { BuyingOfferDomainModule } from './buying-offer';
import { ObjectDomainModule } from './object';
import { PreListingDomainModule } from './pre-listing';
import { TeamDomainModule } from './team';
import { ExclusiveAgreementDomainModule } from './exclusive-agreement';
import { BusinessCustomerDomainModule } from './business-customer';
import { RentalContractDomainModule } from "./rental-contract";
import { CustomerDomainModule } from './customer';
import { CreditCheckDomainModule } from "./credit-check";
import { ExposeGeneratorDomainModule } from "./expose-generator";
import { CustomerPortalDomainModule } from './customer-portal';
import { DocumentsDomainModule } from "./documents/documents-domain.module";
import { ContractPartnerDomainModule } from './contract-partner';
import {PlainAgreementDomainModule} from "./plain-agreement";
import {SettingsDomainModule} from "./settings/settings-domain.module";

@NgModule({
  imports: [
    RentalOfferDomainModule,
    HandoverProtocolDomainModule,
    ViewingPassDomainModule,
    PreListingDomainModule,
    ObjectDomainModule,
    TeamDomainModule,
    BuyingOfferDomainModule,
    ExclusiveAgreementDomainModule,
    PlainAgreementDomainModule,
    BusinessCustomerDomainModule,
    RentalContractDomainModule,
    CustomerDomainModule,
    CreditCheckDomainModule,
    ExposeGeneratorDomainModule,
    CustomerPortalDomainModule,
    DocumentsDomainModule,
    ContractPartnerDomainModule,
    SettingsDomainModule,
  ],
})
export class SharedDomainsModule { }
